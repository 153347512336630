<template>
  <div @click.prevent="onClick">
    <slot />
  </div>
</template>

<script>
import { accountService } from '@/_services'
import uploadcare from 'uploadcare-widget'
export default {
  props: {
    publicKey: {
      type: String,
      default: ''
    },
    multiple: {
      type: Boolean,
      default: false
    },
    multipleMax: {
      type: Number
    },
    multipleMin: {
      type: Number
    },
    imagesOnly: {
      type: Boolean,
      default: false
    },
    // Default value does not match the UploadCare API default.
    previewStep: {
      type: Boolean,
      default: true
    },
    crop: {
      type: String,
      default: ''
    },
    imageShrink: {
      type: String,
      default: ''
    },
    clearable: {
      type: Boolean,
      default: false
    },
    tabs: {
      type: String,
      default: 'gallery file url camera dropbox gdrive box skydrive'
    },
    inputAcceptTypes: {
      type: String
    },
    preferredTypes: {
      type: String
    },
    // Default value does not match the UploadCare API default.
    systemDialog: {
      type: Boolean,
      default: true
    },
    multipartMinSize: {
      type: Number,
      default: 26214400
    },
    secureSignature: {
      type: String
    },
    secureExpire: {
      type: Number
    },
    previewProxy: {
      type: String
    },
    previewUrlCallback: {
      type: Function
    },
    cdnBase: {
      type: String
    },
    doNotStore: {
      type: Boolean,
      default: false
    },
    validators: {
      type: Array
    },
    context: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      gallery: [],
      dialogRef: [],
      dialogSettings: {}
    }
  },
  beforeDestroy () {
    if (this.gallery.length > 0) {
      this.gallery.forEach(image => {
        if (typeof window !== 'undefined') {
          window.removeEventListener(`Binds.currentImage-localstorage-changed-${image.uuid}`, this.selectImage)
        }
      })
    }
  },
  methods: {
    async reqs (context) {
      const nReq = await accountService.getImages(context)
      return nReq
    },
    selectImage (uuid) {
      const self = this
      this.dialogRef.addFiles([
        uploadcare.fileFrom('uploaded', uuid, self.dialogSettings)
      ])
    },
    async onClick () {
      const {
        publicKey,
        multiple,
        multipleMax,
        multipleMin,
        imagesOnly,
        previewStep,
        crop,
        imageShrink,
        clearable,
        tabs,
        inputAcceptTypes,
        preferredTypes,
        systemDialog,
        multipartMinSize,
        secureSignature,
        secureExpire,
        previewProxy,
        previewUrlCallback,
        cdnBase,
        doNotStore,
        validators,
        context
      } = this

      const options = {
        publicKey,
        multiple,
        multipleMax,
        multipleMin,
        imagesOnly,
        previewStep,
        crop,
        imageShrink,
        clearable,
        tabs,
        inputAcceptTypes,
        preferredTypes,
        systemDialog,
        multipartMinSize,
        secureSignature,
        secureExpire,
        previewProxy,
        previewUrlCallback,
        cdnBase,
        doNotStore,
        context
      }

      const self = this
      if (validators && validators.length) {
        Object.assign(options, { validators })
      }

      this.gallery = await accountService.getImages(this.context)

      uploadcare.registerTab('gallery', function (
        container,
        button,
        dialogApi,
        settings
      ) {
        self.dialogRef = dialogApi
        self.dialogSettings = settings
        // context tabs
        const allContexts = [
          { name: 'profile', label: 'Profile' },
          { name: 'account', label: 'Conta' },
          { name: 'messages', label: 'Mensagens' },
          { name: 'email', label: 'Email' },
          { name: 'all', label: 'Todos' },
          { name: 'whatsapp', label: 'Whatsapp' }
        ]
        const contextTabs = document.createElement('div')
        contextTabs.setAttribute('class', 'context-tabs')
        const singleTab = document.createElement('div')
        singleTab.setAttribute('class', 'each-context-tabs')
        singleTab.innerHTML =
        '<nav>' +
        '<li><button id="tab-all" onclick="window.dispatchEvent(new CustomEvent(' + "'addContext'" + ', { detail: ' + "'all'" + '}))">Todas</button></li>' +
        '<li><button id="tab-profile" onclick="window.dispatchEvent(new CustomEvent(' + "'addContext'" + ', { detail: ' + "'profile'" + '}))">Perfil</button></li>' +
        '<li><button id="tab-account" onclick="window.dispatchEvent(new CustomEvent(' + "'addContext'" + ', { detail: ' + "'account'" + '}))">Conta</button>' +
        '<li><button id="tab-messages" onclick="window.dispatchEvent(new CustomEvent(' + "'addContext'" + ', { detail: ' + "'messages'" + '}))">Mensagens</button></li>' +
        '<li><button id="tab-email" onclick="window.dispatchEvent(new CustomEvent(' + "'addContext'" + ', { detail: ' + "'email'" + '}))">Email</button></li>' +
        '<li><button id="tab-whatsapp" onclick="window.dispatchEvent(new CustomEvent(' + "'addContext'" + ', { detail: ' + "'whatsapp'" + '}))">Whatsapp</button></li>' +
        '</nav>' +
        '<div class="lds-dual-ring" id="spinner"></div>' +
        '<div class="no-images" id="noImage"><span>Você ainda não possui imagens nessa seção, tente ver <button onclick="window.dispatchEvent(new CustomEvent(' + "'addContext'" + ', { detail: ' + "'all'" + '}))">Todas</button> </span></div>'
        contextTabs.appendChild(singleTab)
        window.addEventListener('addContext', (event) => newReqWithContext(event), false)
        async function newReqWithContext (e) {
          document.getElementById('spinner').style.display = 'inline-block'
          // active and desactive tabs
          allContexts.forEach(k => {
            document.getElementById('tab-' + k.name).removeAttribute('class', 'active-context-upload')
          })
          const currentTab = document.getElementById('tab-' + e.detail)
          currentTab.setAttribute('class', 'active-context-upload')
          const newItems = await self.reqs(e.detail)
          // função de renderizar as imagens com o novo contexto
          displayImages(newItems)
          document.getElementById('spinner').style.display = 'none'
        }
        function displayImages (images) {
          button.attr('title', 'Image gallery')
          // button.setAttribute('class', 'uploadcare-items__item')
          const ul = document.createElement('ul')
          ul.setAttribute('class', 'uploadcare-items')
          ul.setAttribute('id', 'context-items')
          if (images.length > 0) {
            images.forEach(image => {
              window.addEventListener(`Binds.currentImage-localstorage-changed-${image.uuid}`, (event) => {
                self.selectImage(event.detail.storage)
              })
              const li = document.createElement('li')
              li.setAttribute('class', 'uploadcare-items__item')
              li.setAttribute('onclick', `window.dispatchEvent(new CustomEvent('Binds.currentImage-localstorage-changed-${image.uuid}', {
                detail: {
                  storage: '${image.uuid}'
                }
              }))`)
              const img = document.createElement('img')
              img.setAttribute('src', `https://ucarecdn.com/${image.uuid}/-/setfill/ffffff/-/format/jpeg/-/scale_crop/160x160/center/`)
              li.appendChild(img)
              ul.appendChild(li)
            })
          }
          container.html([
            contextTabs,
            ul
          ])
          // alerta caso o contexto nao tenha imagem ainda
          const emptyImagesMessage = document.getElementById('noImage')
          images.length > 0 ? emptyImagesMessage.style.display = 'none' : emptyImagesMessage.style.display = 'block'
          // $compile(container)($scope)
        }
        displayImages(self.gallery)
        // init active tab context
        document.getElementById('tab-' + self.context).setAttribute('class', 'active-context-upload')
      })
      this.fileGroup = uploadcare.openDialog([], options)

      this.fileGroup.done((filePromise) => {
        if (this.multiple) {
          const promise = filePromise.promise()
          promise.done(() => {
            const files = filePromise.files()
            files.forEach((fileProm) => {
              fileProm.done(async (file) => {
                if (file && file.sourceInfo && file.sourceInfo.source !== 'url') {
                  await accountService.saveImageOnAccountGallery({
                    url: file.cdnUrl,
                    name: file.name,
                    uuid: file.uuid,
                    uploadcareFileInfo: file
                  }, this.context)
                }
                this.$emit('success', file)
              })
              fileProm.fail((err) => {
                this.$emit('error', err)
              })
            })
          })
          promise.fail((err) => {
            this.$emit('error', err)
          })
        } else {
          filePromise.done(async (file) => {
            if (file && file.sourceInfo && file.sourceInfo.source !== 'url') {
              await accountService.saveImageOnAccountGallery({
                url: file.cdnUrl,
                name: file.name,
                uuid: file.uuid,
                uploadcareFileInfo: file
              }, this.context)
            }
            this.$emit('success', file)
          })
          filePromise.fail((err) => {
            this.$emit('error', err)
          })
        }
      })
      this.fileGroup.fail((err) => {
        this.$emit('error', err)
      })
    }
  }
}
</script>
<style lang="scss">
.uploadcare--tab_name_gallery {
  align-items: flex-start;
  justify-content: flex-start;
}

.uploadcare--tab_name_gallery {
  padding: 20px;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: block;
  box-sizing: border-box;
}

.uploadcare--tab_name_gallery .uploadcare__no-files {
  padding: 50px 0;
}

.uploadcare--tab_name_gallery .uploadcare-items {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.uploadcare--tab_name_gallery .uploadcare-items__item {
  padding: 0;
  float: left;
  border: 1px solid #f2f2f2;
  box-shadow: 1px 1px 10px rgba(0,0,0,.03);
  margin: 2px;
  cursor: pointer;
}
.uploadcare--tab_name_gallery .uploadcare-items__item:hover {
  border: 1px solid #c9c9c9;
}

.each-context-tabs {
  nav {
    display: flex;
    margin-bottom: 30px;
    li {
      margin: 0 15px;
      .active-context-upload {
        border: 2px solid #1a8cd8;
        color: #1a8cd8;
        box-shadow: 0 1px 1px 0 #1a8cd8 !important;
      }
      button {
        font-size: 14px;
        color: #c4c4c4;
        border: 2px solid #c4c4c4;
        padding: 10px 20px;
        border-radius: 5px;
        font-weight: bold;
        }
      &:hover {
        button {
          border: 2px solid #1a8cd8;
          color: #1a8cd8;
          box-shadow: 0 1px 1px 0 #1a8cd8 !important;
        }
      }
    }
  }
}
.lds-dual-ring {
  display: none;
  width: 80px;
  height: 80px;
  position: absolute;
  top:40%;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 34px;
  height: 34px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #1a8cd8;
  border-color: #1a8cd8 transparent #1a8cd8 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.no-images {
  button {
    color: #1a8cd8;
    font-size: 15px;
  }
}
</style>
